<template>
  <div class="out-wrapper">
    <div class="nav-wrapper">
      <div class="mobile-menu-wrapper">
        <div @click="menuSwitch" class="icon-font">
          <svg-transition
            :size="{ height: 30, width: 30 }"
            ref="transition"
            trigger="none"
          >
            <svg class="icon" aria-hidden="true" slot="initial">
              <use xlink:href="#icon-caidan1"></use>
            </svg>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-guanbi"></use>
            </svg>
          </svg-transition>
        </div>
      </div>
      <div class="mobile-logo-wrapper" @click="$router.push('home')">
        <img src="@/assets/logo/logo.png" alt="" />
      </div>

      <div class="mobile-right-wrapper" @click.stop>
        <div class="icon-font" @click="isShowWallet = !isShowWallet">
          <svg-transition
            :size="{ height: 30, width: 30 }"
            ref="right_transition"
            trigger="none"
          >
            <svg class="icon" aria-hidden="true" slot="initial">
              <use xlink:href="#icon-qianbao1"></use>
            </svg>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-qianbao1"></use>
            </svg>
          </svg-transition>
          <!-- <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-qianbao1"></use>
            </svg> -->
        </div>
        <div class="mobile-right-menu" v-show="isShowWallet">
          <div class="menu-item" v-if="isLogin" @click="copy()">
            <i class="iconfont">&#xe69a;</i>
            <span>{{ netInfo.addressStr }}</span>
          </div>
          <div class="menu-item" v-if="!isLogin" @click="connectWallet">
            <i class="iconfont">&#xe61f;</i>
            <span> {{ $t("navMenus.title.connect") }}</span>
          </div>
          <div
            class="menu-item"
            @click="handleChaneLanguage(language == 'zh' ? 'en' : 'zh')"
          >
            <i class="iconfont">&#xe642;</i>
            <span> {{ $t("navMenus.title.language") }}</span>
          </div>
          <div class="menu-item" v-if="isLogin" @click="logout()">
            <i class="iconfont">&#xe68f;</i>
            <span> {{ $t("navMenus.title.exit") }}</span>
          </div>
        </div>
      </div>
      <div class="menu-wrapper">
        <div class="logo-wrapper" @click="$router.push('home')">
          <img src="@/assets/logo/logo.png" alt="" />
        </div>
        <div class="menu-nav">
          <div class="menu-item tools-btn common-wrapper">
            <a
              href="#"
              @mouseover="isShowCommonMenu = true"
              @mouseleave="isShowCommonMenu = false"
            >
              <i
                class="iconfont tools"
                :class="isShowCommonMenu ? 'rotation-45' : ''"
                >&#xe6447;</i
              >
              <!-- <span class="tools-span">
                {{ $t("navMenus.menus.m1") }}
              </span> -->
              <!-- <i class="iconfont arrow" :class="menuIsShow ? 'rotation' : ''"
                >&#xe87e;</i
              > -->
            </a>
            <div
              class="common-content"
              v-show="isShowCommonMenu"
              @mouseover="isShowCommonMenu = true"
              @mouseleave="isShowCommonMenu = false"
            >
              <div class="common-menu">
                <div
                  class="common-item"
                  v-for="(item, index) of commonMenus"
                  :key="index"
                  @click="goto(item.url)"
                >
                  <div class="item-icon">
                    <i class="iconfont">{{ item.icon }}</i>
                  </div>
                  <div class="item-title">
                    <div class="main">{{ item.title }}</div>

                    <div class="sub">{{ item.desc }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="menu-item tools-btn batch-wrapper">
            <a
              href="#"
              @mouseover="isShowBatchMenu = true"
              @mouseleave="isShowBatchMenu = false"
            >
              <!-- <i class="iconfont tools">&#xe6447;</i> -->
              <span class="tools-span">
                {{ $t("navMenus.menus.m2") }}
              </span>
              <i
                class="iconfont arrow"
                :class="isShowBatchMenu ? 'rotation-180' : ''"
                >&#xe87e;</i
              >
            </a>
            <div
              class="batch-content"
              v-show="isShowBatchMenu"
              @mouseover="isShowBatchMenu = true"
              @mouseleave="isShowBatchMenu = false"
            >
              <div class="batch-menu">
                <div
                  class="batch-item"
                  v-for="(item, index) of batchMenus"
                  :key="index"
                  @click="goto(item.url)"
                >
                  <div class="item-icon">
                    <i class="iconfont">{{ item.icon }}</i>
                  </div>
                  <div class="item-title">
                    <div class="main">{{ item.title }}</div>

                    <div class="sub">{{ item.desc }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="menu-item"
            :class="menu.url == $route.path ? 'active' : ''"
            v-for="(menu, index) in topMenus"
            :key="index"
          >
            <div  @click="jumpUrl(menu.url)">
              <i class="iconfont" v-if="menu.icon">{{ menu.icon }}</i>
              <span>{{ menu.title }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="nav-right-box">
        <div class="search-box"></div>
        <div class="btn-wrapper">
          <div class="language-wrapper">
            <div class="language">
              <i class="iconfont icon-btn" v-show="language == 'zh'"
                >&#xe6444;</i
              >
              <i class="iconfont icon-btn" v-show="language == 'en'"
                >&#xe6445;</i
              >
            </div>
            <div class="international-panel">
              <div
                class="panel-item"
                @click="handleChaneLanguage('zh')"
                :class="language == 'zh' ? 'disabled' : ''"
              >
                <span>简体中文</span>
              </div>
              <div
                class="panel-item"
                @click="handleChaneLanguage('en')"
                :class="language == 'en' ? 'disabled' : ''"
              >
                <span>English</span>
              </div>
            </div>
          </div>

          <div class="btn-item network-div" @click="showModal" v-if="isLogin">
            <span>{{ currentNetName }}</span>
            <i class="iconfont">&#xe87e;</i>
          </div>
          <div class="right-btn" v-if="!isLogin" @click="SHOW_OR_HIDE">
            <span> Connect </span>
          </div>
          <!-- <div class="btn-item" @click="SHOW_OR_HIDE" v-if="!isLogin">
            <div>
              <i class="iconfont top-menu-icon icon-btn">&#xe63e;</i>
            </div>
          </div> -->
          <!-- <div class="btn-item login-wrapper" v-if="isLogin"> -->
          <!-- <div>
              <span class="login-box">{{ web3.addressStr }}</span>
            </div> -->

          <div class="login-wrapper" v-if="isLogin">
            <div class="login-btn">
              <div class="wallet-wrapper">
                <i class="iconfont">&#xe61f;</i>
              </div>
              <div class="wallet-address">
                <span>{{ netInfo.addressStr }}</span>
                <i
                  class="iconfont arrow"
                  :class="walletInfoShow ? 'rotation' : ''"
                  >&#xe87e;</i
                >
              </div>
              <div
                class="login-panel"
                :class="walletInfoShow ? 'login-active' : ''"
              >
                <div class="panel-item" @click="copy()">
                  <i class="iconfont">&#xeb4e;</i>
                  <span>{{ $t("navMenus.other.copy") }}</span>
                </div>
                <div class="panel-item" @click="logout()">
                  <i class="iconfont">&#xe651;</i>
                  <span>{{ $t("navMenus.other.logout") }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Drawer
          title="我是一个抽屉组件"
          :display.sync="mobileMenuIsShow"
          :inner="true"
          :width="drawerWidth"
          :bodyHeight="clientHeight - 80 + 'px'"
          :mask="false"
        >
          <div class="mobile-menu-box">
            <div
              class="mobile-menu-div"
              v-for="(item, index) in mobileMenus"
              :key="index"
            >
              <div class="mobile-menu-title">{{ item.title }}</div>

              <div class="mobile-menu-content">
                <div
                  class="mobile-menu-item"
                  v-for="(menu, i) in item.menus"
                  :key="i"
                  @click="goto(menu.url)"
                >
                  <div class="mobile-menu-icon">
                    <i class="iconfont">{{ menu.icon }}</i>
                  </div>
                  <div class="mobile-menu-name">{{ menu.title }}</div>
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    </div>
    <MyModal
      :title="chooseTitle"
      :visible="visible"
      :showBtn="false"
      @closeModal="closeModal"
    >
      <div v-for="(item, index) in blockNetworks" :key="index">
        <div class="net-type">{{ item.netType }}</div>
        <div class="networks-div">
          <div
            class="networks-item"
            :class="chainId == net.chainId ? 'selected' : ''"
            v-for="(net, index) in item.networks"
            :key="index"
            @click="switchNet(net)"
          >
            <img :src="require('@/assets/imgs/chain/' + net.img)" alt="" />
            {{ net.name }}
          </div>
        </div>
      </div>
    </MyModal>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import MyModal from "@/components/MyModal";
import networks from "@/assets/json/networks.json";
import Drawer from "@/components/Drawer";
export default {
  name: "HeaderNav",
  components: { MyModal, Drawer },
  data() {
    return {
      mobileMenu: "#icon-caidan1",
      mobileMenuIsShow: false,
      isShowBatchMenu: false,
      isShowCommonMenu: false,
      isShowWallet: false,
      clientHeight: 0, //视口高度
      display: false,
      drawerWidth: "100%",
      chooseTitle: this.$t("navMenus.title.chooseNet"),

      mobileMenus: [
        {
          title: this.$t("navMenus.title.common"),

          menus: [
            {
              title: this.$t("navMenus.menus.m3"),

              icon: "\ue6d0",
              url: "/gasTracker",
            },
            {
              title: this.$t("navMenus.menus.sendToken"),

              icon: "\ue68b",
              url: "/tokenSender",
              desc: this.$t("navMenus.menus.subTitle.s1"),
            },
            {
              title: this.$t("navMenus.menus.approveCheck"),

              icon: "\ue68c",
              url: "/approveCheck",
              desc: this.$t("navMenus.menus.subTitle.s4"),
            },
            {
              title: this.$t("navMenus.menus.unitConvert"),

              icon: "\ue62e",
              url: "/unitConvert",
              desc: this.$t("navMenus.menus.subTitle.s5"),
            },
            {
              title: this.$t("navMenus.menus.nftPurchase"),

              icon: "\ue619",
              url: "/nftPurchase",
              desc: this.$t("navMenus.menus.subTitle.s6"),
            },
          ],
        },
        {
          title: this.$t("navMenus.title.batch"),

          menus: [
            {
              title: this.$t("navMenus.menus.sendToken"),

              icon: "\ue68b",
              url: "/tokenSender",
              desc: this.$t("navMenus.menus.subTitle.s1"),
            },
            {
              title: this.$t("navMenus.menus.collectToken"),

              icon: "\ue68a",
              url: "/tokenCollect",
              desc: this.$t("navMenus.menus.subTitle.s2"),
            },
            // {
            //   title: this.$t("navMenus.menus.exchangeListing"),

            //   icon: "\ued04",
            //   url: "/exchangeListing",
            //   desc: this.$t("navMenus.menus.subTitle.exchangeListing"),
            // },
            {
              title: this.$t("navMenus.menus.createWallet"),

              icon: "\ue68d",
              url: "/createWallet",
              desc: this.$t("navMenus.menus.subTitle.s3"),
            },
          ],
        },
        {
          title: this.$t("navMenus.title.other"),

          menus: [
            {
              title: this.$t("navMenus.menus.unitConvert"),

              icon: "\ue62e",
              url: "/unitConvert",
              desc: this.$t("navMenus.menus.subTitle.s5"),
            },
            {
              title: this.$t("navMenus.menus.approveCheck"),

              icon: "\ue68c",
              url: "/approveCheck",
              desc: this.$t("navMenus.menus.subTitle.s4"),
            },
          ],
        },
      ],
      topMenus: [
        {
          title: this.$t("navMenus.menus.m3"),

          icon: null,
          url: "/gasTracker",
        },
        // {
        //   title: this.$t("navMenus.menus.m4"),

        //   icon: null,
        //   url: "/tokenSender",
        // },
        // {
        //   title: this.$t("navMenus.menus.inscription"),

        //   icon: null,
        //   url: "/inscription",
        // },
        // {
        //   title: this.$t("navMenus.menus.turnup"),

        //   icon: null,
        //   url: "/turnup",
        // },
        {
          title: this.$t("navMenus.menus.twitter"),

          icon: null,
          url: "https://twitter.utools.me",
        },
      ],
      commonMenus: [
        // {
        //   title: this.$t("navMenus.menus.intermediator"),

        //   icon: "\uf087",
        //   url: "/intermediator",
        //   desc: this.$t("navMenus.menus.subTitle.intermediator"),
        // },
        {
          title: this.$t("navMenus.menus.nftPurchase"),

          icon: "\ue619",
          url: "/nftPurchase",
          desc: this.$t("navMenus.menus.subTitle.s6"),
        },
        {
          title: this.$t("navMenus.menus.unitConvert"),

          icon: "\ue62e",
          url: "/unitConvert",
          desc: this.$t("navMenus.menus.subTitle.s5"),
        },
        {
          title: this.$t("navMenus.menus.approveCheck"),

          icon: "\ue68c",
          url: "/approveCheck",
          desc: this.$t("navMenus.menus.subTitle.s4"),
        },
      ],
      batchMenus: [
        {
          title: this.$t("navMenus.menus.sendToken"),

          icon: "\ue68b",
          url: "/tokenSender",
          desc: this.$t("navMenus.menus.subTitle.s1"),
        },
        {
          title: this.$t("navMenus.menus.collectToken"),

          icon: "\ue68a",
          url: "/tokenCollect",
          desc: this.$t("navMenus.menus.subTitle.s2"),
        },
        // {
        //   title: this.$t("navMenus.menus.exchangeListing"),

        //   icon: "\ued04",
        //   url: "/exchangeListing",
        //   desc: this.$t("navMenus.menus.subTitle.exchangeListing"),
        // },
        {
          title: this.$t("navMenus.menus.createWallet"),

          icon: "\ue68d",
          url: "/createWallet",
          desc: this.$t("navMenus.menus.subTitle.s3"),
        },
      ],
      menuIsShow: false,
      walletInfoShow: false,
      visible: false,
      blockNetworks: null,
      chainId: null,
      currentNetName: "unknown",
      language: "zh",
    };
  },
  methods: {
    ...mapActions("wallet", ["initData"]),
    ...mapMutations("wallet", [
      "SHOW_OR_HIDE",
      "LOGOUTED",
      "UPDATE_WEB3_INFO",
      "INIT_LOGIN_INFO",
    ]),
    connectWallet() {
      this.isShowWallet = false;
      this.SHOW_OR_HIDE();
    },
    menuSwitch() {
      this.mobileMenuIsShow = !this.mobileMenuIsShow;
      // console.log(this.mobileMenuIsShow);
      this.$refs.transition.performTransition();
    },
    jumpUrl(url){
      if (this.$route.path == url) {
        return;
      }
      if(url.indexOf("http") != -1){
        window.open(url,"_target");
        return;
      }
      this.$router.push({
        path: url,
      });
    },
    goto(url) {
      if (this.mobileMenuIsShow) this.menuSwitch();
      this.isShowBatchMenu = false;
      this.isShowCommonMenu = false;
      if (this.$route.path == url) {
        return;
      }
      this.$router.push({
        path: url,
      });
    },
    handleChaneLanguage(locale) {
      let language = localStorage.getItem("language");
      if (locale !== language) {
        this.$i18n.locale = locale;
        localStorage.setItem("language", locale);
        window.location.reload();
      }
    },
    copy() {
      this.$copyText(this.netInfo.address)
        .then(() => {
          // alert(this.$t("navMenus.other.copySuccess"))
          this.$toast.success(this.$t("navMenus.other.copySuccess"));
        })
        .catch(() => {
          this.$toast.error(this.$t("navMenus.other.copyError"));
        });
    },
    logout() {
      this.LOGOUTED();
      window.location.reload();
    },
    showOrHideMenu() {
      // console.log("show hide");
      this.menuIsShow = !this.menuIsShow;
    },
    showOrHideWalletInfo() {
      this.walletInfoShow = !this.walletInfoShow;
    },
    showModal() {
      this.visible = true;
    },
    closeModal() {
      this.visible = false;
    },
    metaMaskListener() {
      ethereum.on("accountsChanged", (accounts) => {
        // console.log("new account---",accounts[0])
        if (accounts.length === 0) {
          // MetaMask is locked or the user has not connected any accounts
          console.log("Please connect to MetaMask.");
        } else if (accounts[0] !== this.netInfo.address) {
          this.UPDATE_WEB3_INFO({
            address: accounts[0],
            chainId: this.chainId,
          });
          window.location.reload();
        }
      });
      ethereum.on("chainChanged", (chainId) => {
        console.log("new chainId---", web3.utils.hexToNumber(chainId));
        this.UPDATE_WEB3_INFO({
          address: this.netInfo.address,
          chainId: web3.utils.hexToNumber(chainId),
        });
        window.location.reload();
      });
    },
    async switchNet(item) {
      // console.log(item)
      try {
        await ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: web3.utils.numberToHex(item.chainId) }],
        });
      } catch (switchError) {
        console.log("switch network error:", switchError);
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          try {
            await ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: web3.utils.numberToHex(item.chainId),
                  chainName: item.name,
                  rpcUrls: [item.rpcUrl] /* ... */,
                  nativeCurrency: {
                    name: item.token,
                    symbol: item.token, // 2-6 characters long
                    decimals: 18,
                  },
                  blockExplorerUrls: [item.BlockBrowser],
                },
              ],
            });
          } catch (addError) {
            console.log("add network error:", addError);
            // handle "add" error
          }
        }
        // handle other "switch" errors
      }
    },
    closeWallet() {
      this.isShowWallet = false;
    },
  },

  computed: {
    ...mapState("wallet", ["isLogin", "netInfo"]),
  },
  watch: {
    isShowWallet(newValue, oldValue) {
      console.log(newValue, oldValue);
      this.$refs.right_transition.performTransition();
      if (this.isShowWallet) {
        document.body.addEventListener("click", this.closeWallet, false);
      } else {
        document.body.removeEventListener("click", this.closeWallet);
      }
    },
  },
  mounted() {
    let h = document.documentElement.clientHeight || document.body.clientHeight;
    this.clientHeight = h;
    this.initData(); //初始化小狐狸钱包组件
    // this.INIT_LOGIN_INFO();  //初始化用户信息到store
    this.blockNetworks = networks;
    let currentNetwork = {};
    // this.chainId = localStorage.getItem("chainId");
    setTimeout(() => {
      this.chainId = this.netInfo.chainId;
      console.log(this.chainId);
      if (this.chainId) {
        for (let i = 0; i < this.blockNetworks.length; i++) {
          let item = this.blockNetworks[i];
          let nets = item.networks;
          for (let j = 0; j < nets.length; j++) {
            let net = nets[j];
            if (net.chainId == this.chainId) {
              this.currentNetName = net.name;
              // console.log(this.currentNetName)
              currentNetwork = net;
              break;
            }
          }
        }
        localStorage.setItem("currentNetwork", JSON.stringify(currentNetwork));
      }
    }, 1500);
    let lang = localStorage.getItem("language");
    this.language = lang;
    if (web3) {
      this.metaMaskListener();
    }

    // this.clientHeight = window.screen.availHeight;
    // this.$toast.success(this.clientHeight)
    // console.log("window height : ", this.clientHeight);
    // console.log(window.screen.availHeight)
    // window.alert(window.screen.availHeight)
    // let h = document.documentElement.clientHeight || document.body.clientHeight;
    // this.clientHeight = h;
    // console.log(h);

    // console.log("window.innerHeight : ", window.innerHeight);
    // this.clientHeight = h;
    // this.$toast.error(`${h}`);
  },
};
</script>

<style lang="scss" scoped>
* {
  color: #47494e;
}

// $global-color: #002fa7;
.out-wrapper {
  width: 100%;
  height: 80px;
  // min-width: 1200px;
  background-color: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  min-width: 375px;
  position: fixed;
  top: 0px;
  z-index: 997;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  // position: relative;
  .logo-wrapper {
    cursor: pointer;
    margin-left: 40px;
    display: flex;
    align-items: center;
    img {
      // width: 180px;
      height: 40px;
    }
  }

  .nav-wrapper {
    position: relative;
    width: 100%;
    height: 80px;
    // margin: 0 auto;
    // display: flex;
    // justify-content: space-between;
    .mobile-menu-wrapper {
      display: none;
      position: absolute;
      left: 20px;
      top: 25px;
      .icon-font {
        // margin-left: 20px;
        // margin-top: 25px;
        svg {
          cursor: pointer;
        }
      }
      // CSS片段
    }
    .mobile-logo-wrapper {
      display: none;
      position: absolute;
      // left: 0;
      // right: 0;
      // margin: 0 auto;
      left: 50%; //起始是在body中，横向距左50%的位置
      top: 33%; //起始是在body中，纵向距上50%的位置，这个点相当于body的中心点，div的左上角的定位
      transform: translate(-50%, 0); //水平、垂直都居中,也可以写成下面的方式
      img {
        height: 35px;
        width: 100px;
      }
    }
    .mobile-right-wrapper {
      display: none;
      position: absolute;
      right: 20px;
      top: 25px;
      .icon-font {
        svg {
          width: 30px;
          height: 30px;
          cursor: pointer;
        }
      }
      .mobile-right-menu {
        position: absolute;
        top: 40px;
        right: 0px;
        border-radius: 10px;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
        background-color: #fff;
        padding: 10px;
        .menu-item {
          display: flex;
          align-items: center;
          padding: 10px 0;
          font-size: 16px;
          cursor: pointer;
          padding: 10px;
          border-radius: 5px;
          i {
            padding: 0 10px;
            font-size: 20px;
          }
          span {
            white-space: nowrap;
          }
          &:active {
            i {
              color: #fff;
            }
            span {
              color: #fff;
            }

            background-color: $global-color;
          }
        }
      }
    }
    .menu-wrapper {
      display: flex;

      .menu-nav {
        width: 500px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        .menu-item {
          line-height: 80px;
          position: relative;
          cursor: pointer;
          a {
            display: inline-flex;

            i {
              font-size: 20px;
              margin-right: 3px;
            }

            &:hover {
              cursor: pointer;

              i {
                color: $global-color;
              }

              span {
                color: $global-color;
              }
            }
          }
        }

        .menu-item::after {
          content: "";
          width: 0;
          height: 5px;
          background: $global-color;
          position: absolute;
          top: 93%;
          left: 50%;
          transition: all 0.3s;
        }

        .active {
          &::after {
            left: 0%;
            width: 100%;
          }
        }

        .router-link-active {
          i {
            color: $global-color;
          }

          span {
            color: $global-color;
          }
        }

        .tools-btn {
          .arrow {
            margin-left: 3px;
            font-size: 14px;
            transition: all 0.3s;
          }
        }
        .common-wrapper {
          position: relative;

          .tools {
            padding: 0 20px;
            font-size: 24px;
            transition: all 0.3s;
          }
          .common-content {
            background: #fff;
            height: 500px;
            width: 770px;
            position: absolute;
            box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.15);
            border-radius: 10px;
            z-index: 999;
            top: 70px;
            transition: all 0.3s;
            .common-menu {
              margin: 20px;
              line-height: 20px;
              // height: 500px;
              // width: 770px;
              // background-color: #fba;
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              // top:200px;
              .common-item {
                display: flex;
                align-items: center;
                // background-color: #acacac;
                height: 70px;
                border-radius: 10px;
                cursor: pointer;
                &:hover {
                  background: #f9fafb;
                  .item-title {
                    .main {
                      color: $global-color;
                    }
                    .sub {
                      color: $global-color;
                    }
                  }
                }
                .item-icon {
                  margin: 0 30px;
                  i {
                    font-size: 24px;
                    color: $global-color;
                  }
                }
                .item-title {
                  .main {
                    font-size: 16px;
                  }
                  .sub {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
        .batch-wrapper {
          position: relative;

          i {
            transition: all 0.3s;
          }
          .batch-content {
            background: #fff;
            height: 500px;
            width: 770px;
            position: absolute;
            box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.15);
            border-radius: 10px;
            z-index: 999;
            top: 70px;
            transition: all 0.3s;
            .batch-menu {
              margin: 20px;
              line-height: 20px;
              // height: 500px;
              // width: 770px;
              // background-color: #fba;
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              // top:200px;
              .batch-item {
                display: flex;
                align-items: center;
                // background-color: #acacac;
                height: 70px;
                border-radius: 10px;
                cursor: pointer;
                &:hover {
                  background: #f9fafb;
                  .item-title {
                    .main {
                      color: $global-color;
                    }
                    .sub {
                      color: $global-color;
                    }
                  }
                }
                .item-icon {
                  margin: 0 30px;
                  i {
                    font-size: 24px;
                    color: $global-color;
                  }
                }
                .item-title {
                  .main {
                    font-size: 16px;
                  }
                  .sub {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .nav-right-box {
      position: absolute;
      right: 20px;
      top: 0px;
      // width: 460px;
      height: 80px;
      // line-height: 80px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      .btn-wrapper {
        // width: 140px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        // .language-wrapper .right-btn,
        .right-btn {
          padding: 0px 16px;
          background-color: $global-color;
          border-radius: 20px;
          font-size: 14px;
          line-height: 40px;
          min-width: 80px;
          cursor: pointer;

          display: flex;
          align-items: center;
          justify-content: center;
          span {
            color: #fff;
          }

          &:active {
            background-color: $global-check-color;
          }
        }
        .language-wrapper {
          position: relative;
          height: 80px;
          display: flex;
          align-items: center;
          .language {
            margin-right: 10px;
            cursor: pointer;
            i {
              font-size: 28px;
              color: $global-color;
            }
          }
          &:hover {
            .international-panel {
              display: block;
            }
          }
          .international-panel {
            display: none;
            width: 88px;
            height: 90px;
            background: #ffffff;
            border-radius: 8px;
            box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.15);
            position: absolute;
            right: -25px;
            top: 60px;
            padding: 4px 4px;

            &:hover {
              display: block;
            }

            .panel-item {
              margin-top: 2px;
              // width: 56px;
              height: 40px;
              line-height: 40px;
              border-radius: 8px;
              // background: #fdecee;
              display: flex;
              align-items: center;

              // justify-content:center;
              span {
                // display: inline-block;
                margin-left: 16px;
                width: 56px;
                // height: 20px;
                font-size: 14px;
                // font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                // text-align: center;
                color: #181925;
                line-height: 20px;

                i {
                  // margin-left: 10px;
                  font-size: 16px;
                  margin-right: 10px;
                }
              }

              &:hover {
                background: $global-bg-color;
                cursor: pointer;

                span {
                  color: $global-color;

                  i {
                    color: $global-color;
                  }
                }
              }
            }
            .disabled {
              pointer-events: none;
              filter: alpha(opacity=50);
              /*IE滤镜，透明度50%*/
              -moz-opacity: 0.5;
              /*Firefox私有，透明度50%*/
              opacity: 0.5;
              /*其他，透明度50%*/
              color: $global-color;
            }
          }
        }

        .btn-item {
          // height: 80px;
          // line-height: 80px;
          margin-left: 10px;

          div {
            display: inline-flex;

            // line-height: 80px;
            .top-menu-icon {
              width: 24px;
              height: 24px;
              cursor: pointer;
            }

            .icon-btn {
              font-size: 24px;
              color: #5d5e67;
            }

            &:hover {
              .icon-btn {
                color: $global-color;
              }
            }
          }

          //登录成功box
          .login-box {
            margin-left: 20px;
            height: 30px;
            border: 1px solid #dcdfe6;
            line-height: 30px;
            padding: 0 20px;
            border-radius: 5px;
            display: inline-block;
            cursor: pointer;
            font-size: 14px;
            font-weight: 600;
            color: #606266;

            &:hover {
              color: $global-color;
              background-color: $global-bg-color;
            }
          }
        }

        .network-div {
          display: flex;
          align-items: center;
          background-color: $global-bg-color;
          border: 1px solid $global-color;
          border-radius: 10px;
          height: 30px;
          padding: 0 10px;
          cursor: pointer;

          span {
            color: $global-color;
            font-size: 14px;
            margin-right: 5px;
            white-space: nowrap;
          }

          i {
            color: $global-color;
            font-size: 12px;
            transition: all 0.3s;
          }
        }

        .login-wrapper {
          margin-left: 10px;
          height: 80px;
          position: relative;
          cursor: pointer;

          // display: flex;
          // align-items: center;
          .login-btn {
            display: flex;
            align-items: center;
            // border: 1px solid #fba;
            height: 80px;

            .wallet-wrapper {
              width: 30px;
              height: 30px;
              border: 3px solid $global-color;
              background-color: #fff;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 22px;
              left: 7px;

              i {
                color: $global-color;
              }
            }

            .wallet-address {
              // display: inline-block;
              // line-height: 0%;
              border: 1px solid $global-color;
              border-radius: 10px;
              padding: 5px 10px 5px 20px;
              font-size: 14px;
              font-weight: 600;
              color: #606266;
              margin-left: 27px;

              display: flex;
              align-items: center;

              i {
                margin-left: 5px;
                font-size: 12px;
                transition: all 0.3s;
              }

              .rotation {
                transform: rotate(180deg);
              }
            }

            &:hover {
              .wallet-address {
                .arrow {
                  transform: rotate(180deg);
                }
              }

              .login-panel {
                display: block;
              }
            }

            .login-panel {
              display: none;
              width: 140px;
              height: 90px;
              background: #ffffff;
              border-radius: 8px;
              box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.15);
              position: absolute;
              right: 0;
              // left: -61px;
              top: 70px;
              padding: 4px 4px;

              &:hover {
                display: block;
              }

              .panel-item {
                margin-top: 2px;
                // width: 56px;
                height: 40px;
                line-height: 40px;
                border-radius: 8px;
                // background: #fdecee;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-left: 5px;

                i {
                  font-size: 18px;
                  padding-right: 5px;
                }

                // justify-content:center;
                span {
                  // display: inline-block;
                  // margin-left: 16px;
                  // width: 56px;
                  // height: 20px;
                  font-size: 14px;
                  // font-family: PingFangSC, PingFangSC-Regular;
                  font-weight: 400;
                  // text-align: center;
                  color: #181925;
                  line-height: 20px;

                  i {
                    // margin-left: 10px;
                    font-size: 16px;
                    margin-right: 10px;
                  }
                }

                &:hover {
                  background: $global-bg-color;
                  cursor: pointer;

                  span {
                    color: $global-color;

                    i {
                      color: $global-color;
                    }
                  }
                }
              }
            }
          }

          .login-active {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }

  .net-type {
    font-weight: bold;
    margin-bottom: 10px;
  }
  .networks-div {
    width: 500px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .networks-item {
      cursor: pointer;
      line-height: 24px;
      border: 1px solid $global-color;
      border-radius: 5px;
      padding: 5px 10px;
      font-size: 14px;
      color: black;
      flex: 0 0 40%;
      text-align: left;
      /* 边距懒得算，css函数代替 */
      margin-right: calc(4% / 2);
      margin-bottom: calc(4% / 2);

      /* 去除每行尾的多余边距 */
      // &:nth-child(2n) {
      //   margin-right: 0;
      // }
      /* 使最后一个元素的边距填满剩余空间 */
      // &:last-child {
      //   margin-right: auto;
      // }
      img {
        width: 12px;
        height: 12px;
      }

      &:hover {
        color: $global-color;
        background-color: $global-bg-color;
      }
    }

    .selected {
      pointer-events: none;
      color: $global-color;
      background-color: $global-bg-color;
    }
  }
  .mobile-menu-box {
    .mobile-menu-div {
      .mobile-menu-title {
        padding: 10px;
        font-size: 14px;
        font-weight: bold;
      }
      .mobile-menu-content {
        width: 100%;
        background-color: #fff;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        .mobile-menu-item {
          display: flex;
          padding: 20px;
          cursor: pointer;
          i {
            color: $global-color;
          }
          & div:first-of-type {
            // padding: 0 10px;
            padding-right: 5px;
          }
          &:hover {
            div {
              color: $global-color;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1000px) {
    .nav-wrapper {
      .mobile-menu-wrapper,
      .mobile-right-wrapper,
      .mobile-logo-wrapper {
        display: block;
      }
      .menu-wrapper,
      .nav-right-box {
        display: none;
      }
    }
  }
}
</style>