import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routers = [
  {
    name: 'home',
    path: '/',
    redirect: '/home',
    component: () => import('@/pages/index'),
    children: [
      {
        path: '/home',
        name: '首页',
        component: () => import('@/pages/home/index'),
        meta: {
          title: '首页',
          icon: ''
        }
      },
      {
        path: '/mintMonitor',
        component: () => import('@/pages/mintMonitor/index'),
        name: 'mint监控'
      },
      {
        path: '/gasTracker',
        component: () => import('@/pages/gasTracker/index'),
        name: 'gas查询'
      },
      {
        path: '/tokenSender',
        component: () => import('@/pages/tokenSender/index'),
        name: '发送Token',
        meta: {
          metaInfo: {
            title: '批量发送Token',
            keywords:
              'ethereum,multisender,disperse,batchsender,metamask,批量转币,常用工具,代币转账,一对多转账,空投,ERC20,NFT multisend,Token BulkSender,token bulk sender,Crypto Airdrop Tool',
            description:
              '批量发送代币工具，适用于以太坊主网、币安BSC智能链、火币Heco生态链等，批量操作方便快捷省时省力！拿空投撸羊毛的必备工具 Airdrop Tool'
          }
        }
      },
      {
        path: '/tokenCollect',
        component: () => import('@/pages/tokenCollect/index'),
        name: '归集Token',
        meta: {}
      },
      {
        path: '/createWallet',
        component: () => import('@/pages/createWallet/index'),
        name: '生成钱包',
        meta: {}
      },
      {
        path: '/approveCheck',
        component: () => import('@/pages/approveCheck/index'),
        name: '授权检查',
        meta: {}
      },
      {
        path: '/unitConvert',
        component: () => import('@/pages/unitConvert/index'),
        name: '单位换算',
        meta: {}
      },
      {
        path: '/nftPurchase',
        component: () => import('@/pages/nftPurchase/index'),
        name: 'NFT抢购',
        meta: {}
      },
      {
        path: '/exchangeListing',
        component: () => import('@/pages/exchangeListing/index'),
        name: '批量交易所挂单',
        meta: {}
      },
      {
        path: '/exchangeNFTDetail',
        component: () => import('@/pages/exchangeNFTDetail/index'),
        name: 'exchangeNFTDetail',
        meta: {}
      },
      {
        path: '/intermediator',
        component: () => import('@/pages/intermediator/index'),
        name: 'intermediator',
        meta: {}
      },
      {
        path: '/inscription',
        component: () => import('@/pages/inscription/index'),
        name: 'inscription',
        meta: {}
      },
      {
        path: '/turnup',
        component: () => import('@/pages/turnup/index'),
        name: 'turnup',
        meta: {}
      },
      {
        path: '/vipPurchase',
        component: () => import('@/pages/vipPurchase/index'),
        name: 'vipPurchase',
        meta: {}
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes: routers
})

export default router
