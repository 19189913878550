<template>
  <div class="container">
    <transition name="modal-fade">
      <div class="modal-container" v-show="visible">
        <!-- 头部标题 -->
        <div class="modal-header">
          <div class="modal-title">{{ title }}</div>
          <i class="iconfont close-icon" @click="close">&#xe7fc;</i>
        </div>
        <!-- 内容区域 -->
        <div class="modal-content">
          <slot></slot>
        </div>
        <!-- 底部按钮 -->
        <div class="modal-footer" v-show="showBtn">
          <div class="modal-btn">
            <button
              class="cancel"
              ref="cancelBtn"
              @click="close"
              @mousedown="cancelMouseDown"
              @mouseup="cancelMouseUp"
            >
              {{ $t("tokenSender.btn.cancle") }}
            </button>
            <button class="ok" @click="ok">
              {{ $t("tokenSender.btn.confirm") }}
            </button>
          </div>
        </div>
      </div>
    </transition>
    <!-- 遮罩层 -->
    <div class="mask" v-show="visible" @click="close"></div>
  </div>
</template>
   
  <script>
export default {
  name: "MyModal",
  data() {
    return {};
  },
  props: {
    // 模态框标题
    title: {
      type: String,
      default: () => {
        return "Title";
      },
    },
    // 显示隐藏控件
    visible: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    showBtn: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    closeMethod: {
      // 选中时的内容
      type: [String],
      default: "closeModal",
    },
    okMethod: {
      // 选中时的内容
      type: [String],
      default: "confirmModal",
    },
  },
  methods: {
    // 取消
    close() {
      //   console.log("sub-close");
      //   console.log(this)
      // this.$parent.closeModal();
      //   this.$emit("close");
      //   this.$parent.$emit("close");
      //   this.$emit("update:visible", false);
      this.$emit(`${this.closeMethod}`);
    },
    // 确认
    ok() {
      // this.$parent.confirmModal()
      // this.$emit("update:visible", false);
      // console.log(this.okMethod);
      this.$emit(`${this.okMethod}`);
    },
    // 取消按钮 鼠标按下事件
    cancelMouseDown() {
      this.$refs.cancelBtn.style.color = "#096dd9";
      this.$refs.cancelBtn.style.border = "1px solid #096dd9";
    },
    // 取消按钮 鼠标松开事件
    cancelMouseUp() {
      this.$refs.cancelBtn.style.color = "#595959";
      this.$refs.cancelBtn.style.border = "1px solid #d9d9d9";
    },
  },
  watch: {
    // 操作遮罩层的展示/隐藏
    visible() {
      if (this.visible == true) {
        document
          .querySelector("body")
          .setAttribute("style", "overflow:hidden !important;");
      } else {
        document.querySelector("body").removeAttribute("style");
      }
    },
  },
};
</script>
   
  <style lang="scss" scoped>
.modal-container {
  z-index: 999;
  background-color: #fff;
  min-width: 350px;
  min-height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: none;
  border-radius: 4px;
  transition: 0.5s;
  // box-shadow: 5px 5px 5px #e4e4e4, 5px -5px 5px #e4e4e4, -5px 5px 5px #e4e4e4, -5px -5px 5px #e4e4e4;
  // 不需要知道宽高 水平垂直居中
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  // 头部标题
  .modal-header {
    // width: 100%;
    height: 20px;
    border: none;
    border-bottom: 1px solid #e8e8e8;
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background-color: aquamarine;
    .modal-title {
      color: #262626;
      font-weight: 600;
      font-size: 17px;
    }
    .close-icon {
      color: #4d4d4d;
      cursor: pointer;
      width: 80px;
      text-align: right;
    }
  }
  // 内容
  .modal-content {
    // width: 100%;
    min-height: 100px;
    border: none;
    border-radius: none;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  // 底部按钮
  .modal-footer {
    // width: 100%;
    height: 60px;
    border: none;
    border-top: 1px solid #e8e8e8;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .modal-btn {
      width: 150px;
      display: flex;
      justify-content: space-between;
      .cancel {
        border: 1px solid #d9d9d9;
        background-color: #fff;
        color: #595959;
        width: 70px;
        height: 32px;
        border-radius: 4px;
        font-size: 14px;
        transition: 0.5s;
        cursor: pointer;
        &:hover {
          border: 1px solid $global-color;
          color: $global-color;
        }
      }
      .ok {
        border: 1px solid $global-color;
        background-color: $global-color;
        color: #ffffff;
        width: 70px;
        height: 32px;
        border-radius: 4px;
        font-size: 14px;
        transition: 0.5s;
        cursor: pointer;
        &:hover {
          border: 1px solid $global-color;
          background-color: $global-color;
        }
      }
    }
  }
}
// 遮罩层
.mask {
  z-index: 998;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // opacity: 0.4 !important;
  // background: #000000 !important;
  background-color: rgba(0, 0, 0, 0.3);
}
// 模态框展示隐藏的动画
.modal-fade-enter-active {
  transition: all 0.3s ease;
}
.modal-fade-leave-active {
  transition: all 0.3s ease;
}
.modal-fade-enter-from,
.modal-fade-leave-to {
  transform: translateX(-400px);
  opacity: 0;
}
@font-face {
  font-family: "iconfont"; /* Project id 3147059 */
  src: url("//at.alicdn.com/t/font_3147059_ypw5b7teii.woff2?t=1648289780095")
      format("woff2"),
    url("//at.alicdn.com/t/font_3147059_ypw5b7teii.woff?t=1648289780095")
      format("woff"),
    url("//at.alicdn.com/t/font_3147059_ypw5b7teii.ttf?t=1648289780095")
      format("truetype");
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 18px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
</style>