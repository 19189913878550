<template>
  <div
    :class="visible ? 'show' : 'hide'"
    class="toast-wrapper"
    @click="hideToast"
  >
    <div class="toast-box success" v-show="type === 'success'">
      <i class="iconfont">&#xe6ac;</i>
      <span>{{ message }}</span>
    </div>
    <div class="toast-box error" v-show="type === 'error'">
      <i class="iconfont">&#xe8e7;</i>
      <span>{{ message }}</span>
    </div>
    <div class="toast-box info" v-show="type === 'info'">
      <i class="iconfont">&#xe8ea;</i>
      <span>{{ message }}</span>
    </div>
    <div class="toast-box waring" v-show="type === 'waring'">
      <i class="iconfont">&#xe669;</i>
      <span>{{ message }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      message: "",
      type: "",
    };
  },
  // 存放方法事件 - 会在数据变化时被调用
  methods: {
    hideToast() {
      this.visible = false;
    },
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
};
</script>
<style lang="scss" scoped>
.toast-wrapper {
  position: fixed;
  z-index: 9999;
  //   top: -46px;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 380px;
  transition: all 0.5s;
  .toast-box {
    padding: 0 20px;
    // height: 30px;
    line-height: 46px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 14px;
    }
    span {
      display: inline-block;
      font-size: 14px;
      margin-left: 5px;
    }
  }
  .success {
    color: #67c23a;
    background-color: #f0f9eb;
  }
  .error {
    color: #f56c6c;
    background-color: #fef0f0;
  }
  .info {
    color: #009ef7;
    background-color: #d9f1fe;
  }
  .waring {
    color: #eaa23c;
    background-color: #fdf6ec;
  }
}
.hide {
  top: -46px;
}
.show {
  top: 20px;
}

/* vue动画过渡效果设置 */
// .fade-enter-active,
// .fade-leave-active {
//   transition: opacity 0.5s;
// }
// .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
//   opacity: 0;
// }
</style>