<template>
  <div class="wallet-wrapper"  @click.self="SHOW_OR_HIDE">
    <div class="wallet-box">
      <div class="box-item" @click="connectMetaMask">
        <img src="@/assets/wallet/metamask.png" alt="MetaMask" />
        <span>MetaMask</span>
      </div>
      <div class="box-item">
        <img src="@/assets/wallet/wallet.png" alt="WalletConnect" />
        <span>WalletConnect</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
  name: "Wallet",
  data() {
    return {
    };
  },
  // 存放方法事件 - 会在数据变化时被调用
  methods: {
    ...mapActions("wallet", ["connectMetaMaskAction"]),
    ...mapMutations("wallet", ["SHOW_OR_HIDE"]),
    connectMetaMask() {
      this.connectMetaMaskAction();
    },
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted() {

  },
};
</script>
<style lang="scss" scoped>
.wallet-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  .wallet-box {
    width: 350px;
    height: 200px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
    .box-item {
      width: 150px;
      height: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 70px;
        height: 70px;
      }
      span {
        margin-top: 10px;
        display: block;
      }

      &:hover {
        // background-color: rgba(0, 0, 0, 0.2) ;
        cursor: pointer;
        span {
          color: $global-color;
        }
      }
    }
  }
}
</style>