<template>
  <div class="footer">
    <div class="footer-wrapper">
      <div class="left-wrapper">
        {{$t("footer.title.left")}}
      </div>
      <div class="right-wrapper">
        <div class="right-item" v-for="(item, index) in iconList" :key="index">
          <span @click="goto(item.url)">
            <svg class="icon icon-font" aria-hidden="true">
              <use :xlink:href="item.icon"></use>
            </svg>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FooterNav",
  data() {
    return {
      iconList: [
        {
          icon: "#icon-tuitetwitter43",
          url: "https://twitter.com/utools_web3",
        },
        {
          icon: "#icon-discord",
          url: "https://discord.gg/E8UHv228",
        },
        {
          icon: "#icon-telegram",
          url: "https://t.me/UTools_web3_channel",
        },
        {
          icon: "#icon-Emailus",
          url: "email",
        },
      ],
    };
  },
  methods:{
    goto(url){
      if(url == "email"){
        window.open("mailto:utools0x@gmail.com", "_blank"); // 新窗口打开外链接
      }else{
        window.open(url, "_blank"); // 新窗口打开外链接
      }
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  color: #47494e;
  text-decoration: none;
}
.footer {
  width: 100%;
  // margin-top: 50px;
  height: 100px;
  background-color: #fff;
  // position: absolute;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  min-width: 375px;
  .footer-wrapper {
    height: 100px;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // line-height: 100px;
    margin: 0 auto;
    .left-wrapper{
      font-size: 14px;
      font-weight: bold;
    }
    .right-wrapper {
      display: flex;
      div {
        margin: 0 15px;
      }
      .right-item {
        span {
          cursor: pointer;
          font-size: 30px;
          color: $global-color;
        }
      }
    }
    @media only screen and (max-width: 900px) {
      flex-direction: column;
      align-items:center;
      justify-content:flex-end;
      .left-wrapper{
        margin-bottom: 10px;
      }
      .right-wrapper {
        margin-bottom: 10px;
      }
    }
  }
}
</style>