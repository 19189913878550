import Web3 from 'web3/dist/web3.min.js'
let state = {
    isClose: false,
    isLogin: false,
    netInfo: {
        web3Instance: null,
        chainId: null,
        balance: null,
        address: null
    },
}



const actions = {
    //链接小狐狸钱包
    async connectMetaMaskAction({ commit }, payload) {
        
        if (!web3) {
            this._vm.$toast.error("当前浏览器未安装Metamask扩展")
            return;
        }
        // this._vm.$toast.error("connectMetaMaskAction")
        // console.log("connectMetaMaskAction")
        const accounts = await window.ethereum
            .request({ method: "eth_requestAccounts" })
            .then((accounts) => {
                const address = accounts[0];
                const chainId = window.ethereum.networkVersion;
                window.web3 = new Web3(window.web3.currentProvider);
                console.log(address);
                let isLogin = localStorage.getItem("isLogin");
                if (!isLogin) {
                    commit("CONNECTED_METAMASK", { address, web3Instance: window.web3, chainId })
                }else{
                    commit("INIT_LOGIN_INFO",{ address,chainId})
                }
            })
            .catch((error) => {
                console.error('error', error)
                if (error.code === 4001) {
                    // EIP-1193 userRejectedRequest error
                    //console.log("用户取消登录.");
                    // this._vm.$toast.error(this._vm.$i18nn.t('wallet.toast.t1'))
                } else {
                    console.error(error);
                }
            });
    },
    async initData({ dispatch }) {
        let isLogin = localStorage.getItem("isLogin");
        if (isLogin) {
            dispatch("connectMetaMaskAction")
        }
    }
}

const mutations = {
    //链接小狐狸钱包成功
    CONNECTED_METAMASK(state, payload) {
        // debugger
        // console.log('CONNECTED_METAMASK-payload---', payload)
        let isLogin = localStorage.getItem("isLogin");
        if (isLogin) {
            return; //如果已经登录过了，就不在往下走了
        }
        let netInfoCopy = state.netInfo
        netInfoCopy.address = payload.address
        // netInfoCopy.web3Instance = payload.web3Instance
        netInfoCopy.chainId = payload.chainId
        netInfoCopy.addressStr = payload.address.substr(0, 4) + "...." + payload.address.substr(38)
        state.netInfo = netInfoCopy
        state.isClose = false
        state.isLogin = true
        localStorage.setItem('isLogin', true)
        localStorage.setItem('chainId', netInfoCopy.chainId)
        localStorage.setItem('address', payload.address)
        localStorage.setItem('userInfo', JSON.stringify(netInfoCopy))
        // console.log('state，，，，', state)
        window.location.reload();
    },
    SHOW_OR_HIDE(state, payload) {
        state.isClose = !state.isClose
    },
    LOGOUTED(state, payload) {
        state.netInfo = {}
        state.isLogin = false
        this._vm.$toast.success(
            this._vm.$i18nn.t('navMenus.other.logoutSuccess')
        );
        localStorage.removeItem('isLogin')
        localStorage.removeItem('chainId')
        localStorage.removeItem('currentNetwork')
        localStorage.removeItem('userInfo')
    },
    UPDATE_WEB3_INFO(state, payload) {
        let netInfoCopy = state.netInfo
        netInfoCopy.address = payload.address
        netInfoCopy.chainId = payload.chainId
        netInfoCopy.addressStr = payload.address.substr(0, 4) + "...." + payload.address.substr(38)
        localStorage.setItem('chainId', netInfoCopy.chainId)
        localStorage.setItem('address', payload.address)
        state.netInfo = netInfoCopy
        localStorage.setItem('userInfo', JSON.stringify(netInfoCopy))
    },
    INIT_LOGIN_INFO(state, payload) {
        // console.log('has login')
        let isLogin = localStorage.getItem("isLogin");
        if (isLogin) {
            let userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if(userInfo.address != payload.address){
                userInfo.address = payload.address;
                userInfo.addressStr = payload.address.substr(0, 4) + "...." + payload.address.substr(38)
                localStorage.setItem('address', payload.address)
            }
            let chainId = localStorage.getItem("chainId");
            if(!chainId || chainId != payload.chainId){
                userInfo.chainId = payload.chainId;
                localStorage.setItem('chainId', payload.chainId)
            }
            state.netInfo = userInfo
            state.isClose = false
            state.isLogin = true
            localStorage.setItem('userInfo', JSON.stringify(userInfo))
            // console.log("INIT_LOGIN_INFO-state : ", JSON.stringify(state))
        }
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}