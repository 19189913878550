<template>
  <div id="app">
    <div class="main-box">
      <HeaderNav></HeaderNav>
      <div class="m-router-view">
        <router-view></router-view>
      </div>
      <FooterNav></FooterNav>
    </div>
    <Wallet v-if="isClose"></Wallet>
    <Toast></Toast>
  </div>
</template>

<script>
import HeaderNav from "@/components/HeaderNav";
import FooterNav from "@/components/FooterNav";
import Wallet from "@/components/Wallet";
import Toast from "@/components/Toast";
import { mapState } from "vuex";
export default {
  name: "App",
  components: {
    HeaderNav,
    FooterNav,
    Wallet,
    Toast,
  },
  data() {
    return {
      screenWidth: 0,
    };
  },
  computed: {
    ...mapState("wallet", ["isClose"]),
  },
  watch: {
    screenWidth: function (n, o) {
      this.$store.state.system.screenWidth =
        document.documentElement.clientWidth; //窗口宽度
      if (n <= 1200) {
        // console.log("屏幕宽度小于1200了");
      } else {
        // console.log("屏幕宽度大于1200");
      }
    },
  },
  mounted() {
    //获取屏幕尺寸
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
};
</script>

<style>
* {
  /* margin: 0; */
  /* padding: 0; */
}
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* width: 100%;*/
  height: 100%;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.main-box {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.m-router-view {
  margin-top: 80px;
  flex: 1;
  /* background: #f9f9f9; */
  background: #fff;
  /* padding-bottom: 50px; */
  /* padding: 0 20px; */
}
</style>
